.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #1a1a1a;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.App-header nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
}

.cta {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.Hero {
  background-color: #f0f0f0;
  padding: 50px 20px;
}

.Hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.Hero p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.Features, .Services, .Testimonials, .CallToAction {
  padding: 50px 20px;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  max-width: 200px;
  margin: 10px;
  text-align: left;
}

.App-footer {
  background-color: #1a1a1a;
  color: white;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content a {
  color: white;
  text-decoration: none;
}
